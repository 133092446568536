import React from "react";

import useLakotaThankYouVideoData from "@staticQueries/lakotaAc/ThankYouVideoQuery";
import useLgcaThankYouVideoData from "@staticQueries/lgcaAc/ThankYouVideoQuery";
import useRomeroThankYouVideoData from "@staticQueries/romero/ThankYouVideoQuery";
import useLgcaAcDefaultsData from "@staticQueries/lgcaAc/DefaultsQuery";
import useLakotaAcDefaultsData from "@staticQueries/lakotaAc/DefaultsQuery";
import useRomeroDefaultsData from "@staticQueries/romero/DefaultsQuery";
import { Text, Icon } from "@atoms";
import brand from "@utils/brand";
import classNames from "classnames";
import loadable from "@loadable/component";
import Share from "./Share";

const Video = loadable(() => import("../atoms/Video"));

const CardCta = ({
  heading,
  copy,
  link,
  image,
  url,
  formid,
  shareUrlOverride,
  trackingId,
  backLink,
  shareCta,
  play,
  status,
}) => {
  let videos;
  let defaults;
  const { lakota, lgca, romero } = brand;
  if (lakota) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    videos = useLakotaThankYouVideoData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaults = useLakotaAcDefaultsData();
  }
  if (lgca) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    videos = useLgcaThankYouVideoData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaults = useLgcaAcDefaultsData();
  }
  if (romero) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    videos = useRomeroThankYouVideoData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaults = useRomeroDefaultsData();
  }

  return (
    <div className="bg-white p-3 shadow-lg">
      <div className="flex flex-wrap items-center gap-3 sm:flex-nowrap sm:gap-6 lg:p-6">
        <div className="order-1 w-full sm:order-2 sm:w-1/2">
          <div>
            <Text
              variant={!lgca ? "h4" : null}
              className={classNames("font-bold", {
                "leading-tighter font-serif text-4xl text-orange sm:text-h3":
                  lgca,
                "text-black": lakota,
              })}
            >
              {heading ||
                (status === "completed"
                  ? defaults.action.completedHeading
                  : defaults.action.thankYouHeading)}
            </Text>
          </div>
          <div className="my-3">
            <Text variant={romero ? "body" : "body"}>
              {copy ||
                (status === "completed"
                  ? defaults.action.completedCopy
                  : defaults.action.thankYouCopy)}
            </Text>
          </div>
          <Share
            cta={shareCta || defaults.action.shareCta}
            shareUrlOverride={shareUrlOverride}
            trackingId={trackingId}
            formid={formid}
            url={url}
            dark
            color={!lakota ? "blue" : "red"}
            small={romero}
          />
        </div>
        {!!videos?.length && !!videos[0]?.length && (
          <div className="relative order-2 w-full sm:order-1 sm:w-1/2">
            {/* show random video */}
            <Video
              // muted
              // autoplay
              url={videos[Math.floor(Math.random() * videos.length)]}
              play={play}
            />
          </div>
        )}
        {!videos[0]?.length && lgca && (
          <div className="relative order-2 flex w-full items-center justify-center px-3 sm:order-1 sm:w-1/2">
            <Icon name="lgcaSun" className="h-20 w-20" />
          </div>
        )}
      </div>
    </div>
  );
};

CardCta.defaultProps = {};

export default CardCta;
