import React from "react";

import classNames from "classnames";
import { Image, Text, Button, Icon } from "@atoms";
import { AppLink } from "@base";
import brand from "@utils/brand";

const CardExpanded = ({
  image,
  heading,
  program,
  label,
  url,
  cta,
  targetBlank,
}) => {
  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const colors = {
    lakota: "text-lakota",
    greenpower: "text-greenpower",
    romero: "text-romero",
  };
  const labels = {
    knowledge: "gain knowledge",
    video: "video",
    action: "action",
    report: "report",
  };
  const { romero } = brand;
  return (
    <AppLink
      to={url}
      targetBlank={targetBlank}
      className="group flex h-full w-full flex-col bg-white shadow-lg transition duration-200 hover:shadow-xl"
    >
      <div className="w-full overflow-hidden">
        <div className="transform transition duration-200 group-hover:scale-105">
          <Image image={image} aspectRatio={[8, 5]} />
        </div>
      </div>
      <div className="flex flex-grow flex-col p-3 lg:p-4">
        {label && (
          <div className={`${colors[program]} mb-3 flex items-center`}>
            <Icon
              name={`icon${capitalize(program)}`}
              className="mr-1 h-4 w-4"
            />
            <Text
              className={classNames("text-xxs uppercase", {
                "font-bold": !romero,
              })}
            >
              {labels[label]}
            </Text>
          </div>
        )}
        <Text
          className={classNames("font-serif mb-3 text-1-5xl", {
            "leading-tighter": romero,
            "font-bold": !romero,
          })}
        >
          {heading}
        </Text>
        <div className="mt-auto">
          <Button size="sm" rounded={romero} color={romero ? "blue" : "red"}>
            {cta}
          </Button>
        </div>
      </div>
    </AppLink>
  );
};

CardExpanded.defaultProps = {};

export default CardExpanded;
