/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react";
import { m } from "framer-motion";
import { Image, Text, Icon, AnimateIn, ButtonAlt } from "@atoms";
import brand from "@utils/brand";
import classNames from "classnames";
import { AppLink } from "@base";

const CardProject = ({ icon, heading, copy, image, link, text, small }) => {
  const [hoverOpen, setHoverOpen] = useState(false);
  const [clickOpen, setClickOpen] = useState(false);
  const copyRef = useRef();
  const cardRef = useRef();
  const hasCopy = copy && copy.length > 1;
  const { lgca, lakota, romero } = brand;

  return (
    <AppLink
      tag="div"
      ref={cardRef}
      to={link}
      className={classNames(
        "relative flex aspect-square w-full flex-col overflow-hidden shadow-lg",
        {
          "bg-brown": lakota,
          "bg-red": lgca,
          "h-full rounded-xl bg-blue": romero,
        }
      )}
      onMouseEnter={() => {
        setHoverOpen(true);
      }}
      onMouseLeave={() => {
        setHoverOpen(false);
      }}
    >
      <div className="absolute inset-0 z-10 flex flex-col">
        {/* background image */}
        <div
          className={classNames("absolute inset-0 z-0 mix-blend-screen", {
            "opacity-100": !clickOpen && !hoverOpen,
            "opacity-10": clickOpen || hoverOpen,
          })}
        >
          <div className="absolute inset-0 z-0">
            <Image image={image} fill rounded="xl" />
          </div>
        </div>
        {/* gradient background to make text more legible */}
        <div
          className={classNames(
            "absolute bottom-0 right-0 left-0 z-10 bg-gradient-to-b from-transparent to-black",
            {
              "h-1/3": !romero,
              "h-2/3 opacity-100 transition duration-200 group-hover:opacity-0":
                romero,
            }
          )}
        />
        <div
          className={classNames("relative z-10 flex h-full flex-col lg:p-6", {
            "p-3": !romero,
            "p-4": romero,
          })}
        >
          {/* open/close icon for mobile */}
          {hasCopy && (
            <div className="absolute top-0 right-0 flex h-12 w-12 items-center justify-center">
              <Icon
                name="close"
                className={classNames(
                  "ease absolute h-4 w-4 cursor-pointer transition",
                  {
                    "text-gold duration-200": romero,
                    "text-white duration-200": !romero,
                    "opacity-50 hover:opacity-100": clickOpen && !hoverOpen,
                    "opacity-0": !clickOpen || hoverOpen,
                  }
                )}
                onClick={() => {
                  setClickOpen(false);
                }}
              />
              <Icon
                name="info"
                className={classNames(
                  "ease absolute h-4 w-4 cursor-pointer transition",
                  {
                    "text-gold duration-200": romero,
                    "text-white duration-200": !romero,
                    "opacity-50 hover:opacity-100": !clickOpen && !hoverOpen,
                    "opacity-0": clickOpen || hoverOpen,
                  }
                )}
                onClick={() => {
                  setClickOpen(true);
                }}
              />
            </div>
          )}
          {/* the actual content */}
          <div className="h-full">
            <AnimateIn className="flex h-full flex-col items-start">
              {icon && <Icon name={icon} className="h-12 w-12" />}
              <m.div
                animate={() =>
                  !hoverOpen && !clickOpen
                    ? { y: 0 }
                    : { y: -1 * (copyRef?.current?.clientHeight || 0) }
                }
                className={classNames("mt-auto w-full", {
                  "pr-12": lakota,
                })}
              >
                <Text
                  variant={{ lgca: "h6", lakota: "h4" }[brand]}
                  className={classNames("text-white", {
                    "font-bold": !romero,
                    "font-serif": romero,
                    "text-3xl lg:text-h4": romero && small,
                    "text-3xl sm:text-4xl": romero && !small,
                  })}
                >
                  {heading}
                </Text>
                <div className="relative h-0 w-full">
                  {hasCopy && (
                    <div
                      ref={copyRef}
                      className={classNames(
                        "absolute left-0 top-4 right-0 max-w-xs pb-4 transition duration-200",
                        {
                          "opacity-0": !hoverOpen && !clickOpen,
                        }
                      )}
                    >
                      <Text
                        className={classNames(
                          "text-sm leading-tight text-white md:text-xs",
                          {
                            "font-medium": romero,
                            "font-serif": lakota,
                            "font-sans font-bold": lgca,
                          }
                        )}
                      >
                        {copy}
                      </Text>
                    </div>
                  )}
                </div>
              </m.div>

              <ButtonAlt light color="white" className="mt-4">
                {text}
              </ButtonAlt>
            </AnimateIn>
          </div>
        </div>
      </div>
    </AppLink>
  );
};

CardProject.defaultProps = {};

export default CardProject;
