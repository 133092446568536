import { useStaticQuery, graphql } from "gatsby";
import resolveAction from "@dataResolvers/resolveAction";

const query = graphql`
  query LgcaFooterQuery {
    craft {
      globalSet(handle: "footer", site: "lgca") {
        ... on Craft_footer_GlobalSet {
          social {
            ... on Craft_social_socialLink_BlockType {
              uid
              enabled
              socialUrl {
                url
              }
              platform
            }
          }
          footerNav {
            ... on Craft_footerNav_smallLink_BlockType {
              uid
              enabled
              id
              linkLink {
                url
              }
              linkText
              typeHandle
            }
            ... on Craft_footerNav_largeLink_BlockType {
              uid
              enabled
              id
              typeHandle
              linkLink {
                url
              }
              linkText
            }
            ... on Craft_footerNav_button_BlockType {
              uid
              enabled
              id
              linkText
              linkLink {
                url
              }
              typeHandle
            }
          }
          actionProgression(site: "*") {
            ... on Craft_action_action_Entry {
              url
              siteId
              slug
              formid
              actionType
              backgroundImage0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              activistCodes {
                code
              }
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              activistCodesExclusive {
                code
              }
              heading0
              copy0
              heading3
              copy1
              heading4
              copy2
              heading5
              backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_action_signOnLetter_Entry {
              # meta
              title
              slug
              url
              metaTitle
              metaDescription
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              # site content
              # action details
              heading0
              videoUrl
              alternateAction {
                url
              }
              alternateActionCta
              actionAlignment
              # backgroundImage2 {
              #   width
              #   height
              #   hasFocalPoint
              #   slug
              #   status
              #   title
              #   focalPoint
              #   url
              # }
              copy0
              copy3
              ctaHeading
              backgroundImage0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              # EveryAction Details
              actionType
              formid
              activistCodes {
                code
              }
              activistCodesExclusive {
                code
              }
              text0
              text1
              # thank you fields
              heading3
              copy1
              heading4
              copy2
              shareUrlOverride
              heading5
              backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { actionProgression, footerNav, social } = craft.globalSet;
  return {
    footerNav: footerNav.map(item => {
      const { uid, id, typeHandle, linkLink, linkText } = item;
      return {
        id,
        uid,
        type: typeHandle,
        link: {
          link: linkLink?.url,
          text: linkText,
        },
      };
    }),
    actions: actionProgression.map(action => resolveAction(action)),
    social: social.map(s => {
      return {
        uid: s.uid,
        url: s.socialUrl?.url,
        platform: s.platform,
        enabled: s.enabled,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
