import React, { useState, useEffect } from "react";

import classNames from "classnames";
import brand from "@utils/brand";
import { Text, Icon, DropDown } from "@atoms";
import { m } from "framer-motion";

const MembershipLevelsOptions = ({ levels, actionState, actionDispatch }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { level } = actionState;

  const [activeLevel, setActiveLevel] = useState(level);

  // update level when activeLevel changes
  useEffect(() => {
    if (level !== activeLevel)
      actionDispatch({ type: "setLevel", level: activeLevel });
  }, [activeLevel]);

  const infoVariants = {
    open: { x: "4rem" },
    closed: { x: 0 },
  };

  const detailsVariants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  const { romero, lakota } = brand;
  return (
    <>
      <div className="my-6 md:hidden">
        <DropDown
          options={levels.map(l => l.name)}
          model={[level, setActiveLevel]}
          inverse={!romero}
          sans={romero}
        />
      </div>
      <div
        className={classNames(
          "my-6 flex-grow flex-col border-t text-left text-white md:my-12 md:flex",
          {
            "border-red": !romero,
            "border-gold": romero,
          }
        )}
      >
        {levels.map((l, i) => (
          <button
            type="button"
            className={classNames(
              `level relative mb-6 w-full border-b py-6 text-white`,
              {
                flex: i === level,
                "hidden md:flex": i !== level,
                "border-red": !romero,
                "border-gold": romero,
              }
            )}
            onClick={() => {
              actionDispatch({ type: "setLevel", level: i });
            }}
          >
            <m.span
              variants={infoVariants}
              initial="closed"
              animate={level === i ? "open" : "closed"}
              className="level__info relative -ml-8 block flex w-full flex-col items-center md:ml-0 md:flex-row md:items-start"
            >
              <span
                className={classNames(
                  "absolute left-0 top-0 hidden h-16 w-12 -translate-x-16 transform items-center text-white transition duration-200 md:flex",
                  {
                    "opacity-100": level === i,
                    "opacity-0": level !== i,
                  }
                )}
              >
                <Icon
                  name={romero ? "chevron" : "arrow"}
                  className={classNames("h-8 w-8", {
                    "text-red": !romero,
                    "-rotate-90 transform text-gold": romero,
                  })}
                />
              </span>
              <span className="-ml-16 mb-2 block md:mb-0 md:ml-0 md:mr-4">
                <Icon
                  fitHeight={romero}
                  name={`${l.icon}Membership`}
                  className={classNames(
                    "h-16 w-16 transition duration-200 md:h-12 md:w-12 lg:h-16 lg:w-16",
                    {
                      "text-white": level === i,
                      "text-red": level !== i && !romero,
                      "text-gold": level !== i && romero,
                    }
                  )}
                />
              </span>
              <span className="flex flex-grow">
                <span
                  className={classNames("mr-2 flex flex-shrink-0", {
                    "h-8 items-center": !romero,
                    "h-6 items-end": romero,
                  })}
                >
                  <span
                    className={classNames(
                      "flex h-4 w-4 items-center justify-center rounded-full border-2 transition duration-200",
                      {
                        "border-white bg-red": level === i && !romero,
                        "border-red": level !== i && !romero,
                        "border-white bg-gold": level === i && romero,
                        "border-gold": level !== i && romero,
                      }
                    )}
                  >
                    <Icon
                      name="checkMark"
                      className={`h-2 w-2 text-white
                    ${level === i ? "opacity-100" : "opacity-0"}`}
                    />
                  </span>
                </span>
                <span className="block pr-16">
                  <span className="block">
                    <Text
                      variant={romero ? "h4" : "h5"}
                      className={classNames("text-left", {
                        "text-red": lakota,
                        "text-gold": romero,
                      })}
                    >
                      {l.name}
                    </Text>
                    <Text
                      variant={romero ? "body" : "body-sans"}
                      className={classNames(
                        "rich-text font-sans mt-3 text-left text-sm",
                        {
                          "font-bold": lakota,
                          "font-medium": romero,
                        }
                      )}
                    >
                      {lakota &&
                        `$${l.minMonthly.toLocaleString("en-US")}+ per month`}
                      {romero &&
                        `$${l.minAnnual.toLocaleString("en-US")}+ per year`}
                    </Text>
                  </span>
                  <m.span
                    variants={detailsVariants}
                    initial="closed"
                    animate={level === i ? "open" : "closed"}
                    className="level__details block h-0 overflow-hidden sm:ml-0"
                  >
                    <span className="my-3 block">
                      <Text
                        variant={romero ? "body--large" : "body--large-tight"}
                        className="rich-text text-left"
                      >
                        {l.description}
                      </Text>
                      <Text
                        variant={romero ? "body--small" : "body"}
                        className={classNames("mt-3 text-left", {
                          "font-bold": !romero,
                          "font-medium": romero,
                        })}
                      >
                        {l.benefitsTitle}
                      </Text>
                    </span>
                    <ul
                      className={classNames("my-3 w-full", {
                        "list-outside list-disc pl-8": romero,
                      })}
                    >
                      {l.benefits?.map(b => {
                        return (
                          <li className="mb-3 flex w-full text-left">
                            {!romero && (
                              <Icon
                                className={classNames(
                                  "mx-3 mt-0.5 h-4 w-4 flex-shrink-0 text-red",
                                  {}
                                )}
                                name="arrow"
                              />
                            )}
                            <Text
                              variant={romero ? "body--small" : "body"}
                              className="rich-text"
                            >
                              {b}
                            </Text>
                          </li>
                        );
                      })}
                    </ul>
                  </m.span>
                </span>
              </span>
            </m.span>
            <span className="relative right-0 top-0 block">
              {/* <Icon
                name="close"
                className={`absolute top-0 right-0 text-red h-8 w-8 transition duration-200 ${
                  activeLevel === i ? "opacity-100" : "opacity-0"
                }`}
              /> */}
              <Icon
                name="chevron"
                className={classNames("absolute right-0 top-0 h-8 w-8", {
                  "opacity-100": level !== i,
                  "opacity-0": level === i,
                  "text-red transition duration-200": !romero,
                  "text-gold transition duration-200": romero,
                })}
              />
            </span>
          </button>
        ))}
      </div>
    </>
  );
};

MembershipLevelsOptions.defaultProps = {};

export default MembershipLevelsOptions;
