import React from "react";

import classNames from "classnames";
import { Icon, Text } from "@atoms";
import brand from "@utils/brand";

const HeadingWIcon = ({
  icon,
  heading,
  copy,
  className: _className,
  small,
  inverse,
  centered,
  colors,
  children,
  headingColor: _headingColor,
}) => {
  const { romero } = brand;
  let textColor = inverse ? "text-white" : "text-black";
  let headingColor = _headingColor || (inverse ? "text-white" : "text-black");
  if (colors?.text) {
    textColor = colors.text;
  }
  if (colors?.heading) {
    headingColor = colors.heading;
  }
  return (
    <div
      className={classNames(
        "relative",
        {
          "text-center": centered,
        },
        _className
      )}
    >
      {icon && process.env.GATSBY_CRAFT_SITE_HANDLE.includes("lakota") && (
        <Icon
          name={icon}
          className="text-red-light absolute top-0 left-0 z-0 -ml-12 -mt-8 h-20 w-20 opacity-70"
        />
      )}
      <div className="relative z-10">
        <Text
          variant={small ? "h3" : "h2"}
          className={classNames(headingColor)}
        >
          {heading}
        </Text>
        {copy && (
          <Text
            variant={romero ? "body" : "body--large"}
            inverse={inverse}
            className={classNames(
              {
                "mt-4": romero,
                "mt-3": !romero,
              },
              textColor
            )}
          >
            {copy}
          </Text>
        )}
        {children}
      </div>
    </div>
  );
};

HeadingWIcon.defaultProps = {
  centered: false,
};

export default HeadingWIcon;
