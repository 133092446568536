import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
// eslint-disable-next-line import/no-cycle
import { Share, DonationFeedBanner } from "@molecules";
import { Twitch, Text, Button, ButtonAlt, Icon } from "@atoms";
import loadable from "@loadable/component";

const Video = loadable(() => import("../atoms/Video"));

const Widgetbot = loadable(() => import("@widgetbot/react-embed"));
const Stream = ({
  twitchChannel,
  heading,
  copy,
  button,
  links,
  // share,
  // imagePlaceholder,
  placeholderVideo: videoPlaceholder,
  historySection,
  widget,
  url,
  live,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const embed = useRef();

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      {live && loaded && (
        <div className="fixed right-0 bottom-0 z-30 p-3">
          <div className="relative">
            <Button
              color="white"
              type="button"
              size="xs"
              onClick={() => setShowWidget(true)}
            >
              <Icon name="chat" className="h-8 w-8" fitHeight />
            </Button>
            <div
              ref={widget}
              className={classNames(
                "absolute right-0 bottom-0 z-0 overflow-hidden transition-all duration-200",
                {
                  "z-10 h-[525px] w-[325px] lg:h-[600px] lg:w-[400px]":
                    showWidget,
                  "z-0 h-0 w-0": !showWidget,
                }
              )}
            >
              <div className="widgetbot relative z-0 h-full w-full">
                {typeof window !== "undefined" && (
                  <Widgetbot server={widget.server} channel={widget.channel} />
                )}
              </div>{" "}
              <button
                type="button"
                className={classNames(
                  "absolute top-0 right-0 bg-red p-1 text-white transition duration-200",
                  { "opacity-0": !showWidget }
                )}
                onClick={() => setShowWidget(false)}
              >
                <Icon name="close" className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        ref={embed}
        className="relative w-full bg-black"
        style={{ paddingBottom: "56.25%" }}
      >
        <div className="absolute inset-0 overflow-hidden">
          {live && (
            <Twitch
              videoPlaceholder={videoPlaceholder}
              channel={twitchChannel}
              layout="video"
              muted
            />
          )}
          {!live && typeof window !== "undefined" && (
            <Video url={videoPlaceholder} ratio="16:9" />
          )}
        </div>
      </div>
      <div>
        <DonationFeedBanner />
      </div>
      {/* stream details */}
      <div className="relative flex-grow bg-white p-6">
        <Text variant="h4">{heading}</Text>
        <Text variant="body" className="mt-4">
          {copy}
        </Text>
        <div className="mt-6 flex w-full flex-col flex-wrap items-start space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-6">
          <Button to={button.url} size="sm">
            {button.text}
          </Button>
          {links?.map((link, i) => {
            return <ButtonAlt to={link.url}>{link.text}</ButtonAlt>;
          })}
          <ButtonAlt
            onClick={() => {
              historySection.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            About Us
          </ButtonAlt>
        </div>
        <div className="mt-3">
          <Share
            links
            cta="Invite Friends &amp; Family"
            compact
            small
            dark
            url={url}
            intent="homepage"
          />
        </div>
      </div>
    </>
  );
};

Stream.defaultProps = {};

export default Stream;
