import React, { useMemo } from "react";
import classNames from "classnames";

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
const AnimatedCharacters = React.memo(
  ({ text, className, style, delay, animate }) => {
    //  Split each word of props.text into an array
    const splitWords = text.split(" ");

    // Create storage array
    const words = [];

    // Push each word into words array
    for (const [, itm] of splitWords.entries()) {
      words.push(itm.split(""));
    }

    // Add a space ("\u00A0") to the end of each word
    words.map(word => {
      return word.push("\u00A0");
    });

    const spanStyle = useMemo(
      () => ({
        WebkitTransformStyle: "preserve-3d",
        WebkitBackfaceVisibility: "hidden",
      }),
      []
    );

    return (
      <span
        className={classNames(className, "animate-text text-4xl md:text-h1", {
          "animate-text--play": animate,
        })}
      >
        {words.map((word, index) => {
          const wordDelay =
            delay +
            0.04 *
              ((index + index !== 0
                ? index +
                  words
                    .map(w => w.join(""))
                    .slice(0, index)
                    .join("")
                    .replace(/ /g, "").length
                : 0) -
                index);
          return (
            // Wrap each word in the Wrapper component
            // eslint-disable-next-line react/no-array-index-key
            <span
              className="animate-text__word inline-block italic"
              style={{
                ...spanStyle,
                animationDelay: `${String(wordDelay)}s`,
              }}
              // key={index}
            >
              {word}
              {/* {words[index].flat().map((element, idx) => {
                const charDelay =
                  delay +
                  0.04 *
                    ((idx + index !== 0
                      ? idx +
                        words
                          .map(w => w.join(""))
                          .slice(0, index)
                          .join("")
                          .replace(/ /g, "").length
                      : 0) -
                      index);
                return (
                  <span
                    className="highlight animate-text__char inline-block"
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    style={{
                      animationDelay: `${String(charDelay)}s`,
                      ...(spanStyle || {}),
                    }}
                  >
                    {element}
                  </span>
                );
              })} */}
            </span>
          );
        })}
      </span>
    );
  }
);

AnimatedCharacters.defaultProps = {
  delay: 0,
  animate: false,
};

export default AnimatedCharacters;
