import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query RomeroThankYouVideoQuery {
    craft {
      globalSet(handle: "thankYouVideos", site: "romero") {
        ... on Craft_thankYouVideos_GlobalSet {
          thankYouVideos {
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { thankYouVideos } = craft.globalSet;
  return thankYouVideos.map(video => video.url);
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
