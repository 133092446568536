import React, { useReducer } from "react";

import { AtForm, Text } from "@atoms";
import brand from "@utils/brand";
import { m } from "framer-motion";
import actionReducer from "@reducers/action";
import Share from "./Share";

const ActionShort = ({
  formid,
  activistCodes,
  completedHeading,
  thankYouHeading,
  completedCopy,
  thankYouCopy,
  shareUrlOverride,
  activistCodesExclusive,
  shareCta,
  url,
}) => {
  const [actionState, actionDispatch] = useReducer(actionReducer, {
    trackingId: null,
    status: "unsubmitted",
    // levels,
    amount: null,
    // todo: get initial level from cms
    // level: levels ? 1 : null,
    total: null,
    // todo: make this editable in the CMS
    // eslint-disable-next-line no-nested-ternary
    // frequency: levels?.length ? (romero ? "annual" : "monthly") : "once",
    // visibleAmounts: levels
    //   ? levels?.[1]?.[romero ? "annual" : "monthly"]
    //   : null,
    // amountIdx: 1,
  });

  const actionContainerVariants = {
    submitted: { x: "-50%" },
    reset: { x: 0 },
    completed: { x: "-50%" },
  };
  const actionStepVariants = {
    submitted: { height: 0 },
    reset: { height: "auto" },
    completed: { height: 0 },
  };
  const thankYouStepVariants = {
    submitted: { height: "auto" },
    reset: { height: 0 },
    completed: { height: "auto" },
  };

  const { romero } = brand;

  return (
    <div className="action--short relative w-full overflow-hidden">
      <m.div
        variants={actionContainerVariants}
        animate={actionState.status}
        className="relative z-10 flex"
        style={{
          width: "200%",
        }}
      >
        {/* Action State */}
        <m.div
          variants={actionStepVariants}
          animate={actionState.status}
          className="w-1/2 overflow-hidden px-6"
        >
          <div className="-mx-6 flex flex-wrap justify-start lg:-mx-8">
            <AtForm
              actionDispatch={actionDispatch}
              formId={formid}
              activistCodes={activistCodes}
              actionState={actionState}
              short
            />
          </div>
        </m.div>
        {/* Thank You State */}
        <m.div
          variants={thankYouStepVariants}
          animate={actionState.status}
          className="w-1/2 overflow-hidden text-white"
        >
          <div className="bg-black-alpha p-6">
            <Text variant="body--2xl-tight" className="mb-3 font-bold">
              {actionState.status === "completed"
                ? completedHeading
                : thankYouHeading}
            </Text>
            <Text variant="body" className="mb-3">
              {actionState.status === "completed"
                ? completedCopy
                : thankYouCopy}
            </Text>
            <Share
              small
              cta={shareCta}
              formid={formid}
              trackingId={actionState.trackingId}
              url={url}
              shareUrlOverride={shareUrlOverride}
              color={romero ? "blue" : ""}
            />
          </div>
        </m.div>
      </m.div>
    </div>
  );
};

ActionShort.defaultProps = {};

export default ActionShort;
