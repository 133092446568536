import React from "react";

import { AppLink } from "@base";
import _brand from "@utils/brand";
import classNames from "classnames";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  noIcon,
  color,
  icon,
  light,
  onClick,
}) => {
  const { lakota, lgca, romero, brand } = _brand;
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={classNames(
        "inline-flex items-center gap-2 leading-none",
        _className,
        {
          "font-sans": lakota || romero,
          group: romero,
          "font-extended": lgca,
        }
      )}
    >
      {/* back arrow */}
      {!noIcon && reverse && (
        <span
          className={classNames("icon flex-shink-0", {
            "h-4 w-4 rotate-180 transform": !icon && !romero,
            "h-3 w-3": !icon && romero,
            "h-4 w-4": icon && romero,
            "transition duration-200 group-hover:text-gold": romero,
            "text-white": light,
            "text-gold": !light && romero,
          })}
        >
          <Icon
            name={
              icon ||
              { lgca: "lgcaArrow", romero: "flame", lakota: "arrow" }[brand]
            }
            className={icon ? "h-4 w-4" : null}
          />
        </span>
      )}
      {/* label */}
      <span
        className={classNames(`${color} mb-1 leading-normal`, {
          "text-xs font-bold uppercase": !romero,
          "underline-gold group-hover:underline-gold text-sm font-medium tracking-wide transition duration-200":
            romero,
          "underline-red": lakota || lgca,
          "text-white": light,
          "group-hover:text-gold": !light && romero,
        })}
      >
        {children}
      </span>
      {/* forward arrow */}
      {!noIcon && !reverse && (
        <span
          className={classNames("icon flex-shink-0", {
            "h-3 w-3": !icon && romero,
            "h-4 w-4": icon,
            "transition duration-200 group-hover:text-gold": romero,
            "text-white": light,
            "text-gold": !light && romero,
            "h-4 w-4 text-red": lakota,
          })}
        >
          <Icon
            name={
              icon || { lgca: "lgca", romero: "flame", lakota: "arrow" }[brand]
            }
            className={icon ? "h-4 w-4" : null}
          />
        </span>
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: _brand.romero,
  // noIcon: !brand.lakota,
  color: _brand.romero ? "underline-gold" : "underline-red",
  light: false,
};

export default ButtonAlt;
