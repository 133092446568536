import React from "react";

import { Icon } from "@atoms";
import { AppLink } from "@base";
import classNames from "classnames";

const PopUp = ({
  city,
  name,
  description,
  url,
  facebook,
  twitter,
  ally,
  instagram,
}) => {
  return (
    <div className="font-sans border-2 border-orange p-3 text-xs">
      <div
        className={classNames(
          "font-extended mb-1 text-xxs uppercase tracking-1",
          { "text-red": ally, "text-blue": !ally }
        )}
      >
        {city}
      </div>
      <h2 className="leading-tighter font-extended mb-2 text-base">
        <span
          className={classNames({
            "underline-2-blue": !ally,
            "underline-2-red": ally,
          })}
        >
          {name}
        </span>
      </h2>
      <div
        className="font-sans my-3 block text-xs"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="my-2">
        <AppLink
          className={classNames(
            "font-extended text-orange transition duration-200",
            { "hover:text-blue": !ally, "hover:text-red": ally }
          )}
          to={url}
        >
          {url.replace(/http(s):\/\//, "")}
        </AppLink>
      </div>
      <div className="mt-3 flex gap-3">
        {facebook && (
          <AppLink
            to={facebook}
            className={classNames(
              "transtion flex h-5 w-5 transform items-center justify-center rounded-full duration-200 hover:scale-105",
              { "bg-blue": !ally, "bg-red": ally }
            )}
          >
            <Icon name="facebook" className="h-3 w-3 text-white" fitHeight />
          </AppLink>
        )}
        {twitter && (
          <AppLink
            to={twitter}
            className={classNames(
              "transtion flex h-5 w-5 transform items-center justify-center rounded-full duration-200 hover:scale-105",
              { "bg-blue": !ally, "bg-red": ally }
            )}
          >
            <Icon name="twitter" className="h-3 w-3 text-white" fitHeight />
          </AppLink>
        )}
        {instagram && (
          <AppLink
            to={instagram}
            className={classNames(
              "transtion flex h-5 w-5 transform items-center justify-center rounded-full duration-200 hover:scale-105",
              { "bg-blue": !ally, "bg-red": ally }
            )}
          >
            <Icon name="instagram" className="h-3 w-3 text-white" fitHeight />
          </AppLink>
        )}
      </div>
    </div>
  );
};

PopUp.defaultProps = {};

export default PopUp;
