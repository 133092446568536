import React from "react";
import classNames from "classnames";
import brand from "@utils/brand";
import { Image, Text, AnimateIn } from "@atoms";

const CardEvent = ({ heading, copy, image }) => {
  const { romero, lakota } = brand;
  return (
    <AnimateIn>
      <div className="flex flex-col">
        <div className="relative hidden md:block md:h-80">
          <Image image={image} fill />
        </div>
        <div className="relative md:hidden">
          <Image image={image} />
        </div>
        <div
          className={classNames("mt-4 border-l-3 pl-4", {
            "border-red": lakota,
            "border-gold": romero,
          })}
        >
          <Text
            variant={lakota ? "body--large" : null}
            className={classNames({
              "font-bold text-red": lakota,
              "font-sans text-lg font-medium text-blue": romero,
            })}
          >
            {heading}
          </Text>
          {copy && (
            <Text variant={lakota ? "body" : "body--small"} className="mt-2">
              {copy}
            </Text>
          )}
        </div>
      </div>
    </AnimateIn>
  );
};

CardEvent.defaultProps = {};

export default CardEvent;
