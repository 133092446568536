import React from "react";

import { Text, CbWrapper, Image } from "@atoms";
import Carousel from "./Carousel";

const CbImage = ({ images, caption, indent, type }) => {
  if (images?.length > 1) {
    return (
      <CbWrapper type={type} indent={indent}>
        <Carousel>
          {images.map(image => (
            <div className="flex h-full flex-col items-center justify-center">
              <div className="w-full">
                <Image image={image} />
              </div>
              {(image.caption || caption) && (
                <Text variant="caption">{image.caption || caption}</Text>
              )}
            </div>
          ))}
        </Carousel>
      </CbWrapper>
    );
  }
  if (images?.length) {
    return (
      <CbWrapper type={type} indent={indent}>
        <div className="shadow-xl">
          <Image image={images[0]} />
        </div>
        {(images[0].caption || caption) && (
          <Text variant="caption">{images[0].caption || caption}</Text>
        )}
      </CbWrapper>
    );
  }
  return null;
};

CbImage.defaultProps = {};

export default CbImage;
