import React, { useRef } from "react";

import useDynamicSVGImport from "@utils/useDynamicSVGImport";

const Icon = React.memo(
  ({ name, className: _className, encode, fitHeight }) => {
    const icon = useRef();
    const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
    return (
      <span
        ref={icon}
        className={`romeroicon${
          fitHeight ? "--fit-height" : ""
        } block fill-current ${_className}`}
      >
        {!encode && !loading && SvgIcon && <SvgIcon />}
        {encode && !loading && SvgIcon && (
          <img alt={name} className="block h-full w-full" src={SvgIcon} />
        )}
      </span>
    );
  }
);

Icon.defaultProps = {
  className: "w-16 h-16",
};

export default Icon;
