import React from "react";

import classNames from "classnames";
import brand from "@utils/brand";
import { Text, Button, Image } from "@atoms";

const CardCta = ({ heading, copy, link, image, small }) => {
  const { romero } = brand;
  return (
    <div className="flex flex-wrap bg-white shadow-lg">
      <div
        className={classNames("order-2 md:order-1 md:w-3/5", {
          "p-6 lg:p-10": small,
          "p-3 lg:p-6": !small,
        })}
      >
        <div>
          <Text variant={small ? "h4" : "h3"} className={classNames({})}>
            {heading}
          </Text>
        </div>
        <div className="my-3">
          <Text variant="body">{copy}</Text>
        </div>
        <div className="mt-6">
          <Button
            size={small ? "sm" : "default"}
            to={link.link}
            color={romero ? "blue" : "red"}
            rounded={romero}
          >
            {link.text || "Take Action"}
          </Button>
        </div>
      </div>
      <div className="relative order-1 w-full md:order-2 md:ml-auto md:w-2/5">
        <div className="md:hidden">
          <Image image={image} aspectRatio={[8, 5]} />
        </div>
        <div className="absolute inset-0 hidden md:block">
          <Image image={image} fill />
        </div>
      </div>
    </div>
  );
};

CardCta.defaultProps = { small: false };

export default CardCta;
