import resolveImage from "./resolveImage";
// these resolutions need to be reflexive and recursive
// ! IMPORTANT: if something's broken here, look here
// eslint-disable-next-line import/no-cycle
import resolveAction from "./resolveAction";

const ResolveIssue = issue => {
  if (issue) {
    const {
      uid,
      title,
      slug,
      url,
      metaTitle,
      metaDescription,
      metaImage,
      heading0,
      copy0,
      backgroundImage0,
      featuredAction,
      heading1,
      cardCarousel,
      heading2,
      heading3,
      copy2,
      heading4,
      relatedIssues,
      socialFeed,
      showSocialFeed,
      showImpactGrid,
      impactGrid,
      actions,
      twitterTitle,
      twitterDescription,
      twitterImage,
    } = issue;

    return {
      uid,
      url,
      meta: {
        twitterTitle,
        twitterDescription,
        twitterImage: resolveImage(twitterImage),
        title,
        metaDescription,
        metaImage: resolveImage(metaImage),
        metaTitle,
        slug,
        url,
      },
      slug,
      title,
      // page resolvers
      hero: {
        heading: heading0,
        copy: copy0,
        backgroundImage: resolveImage(backgroundImage0),
        featuredAction: resolveAction(featuredAction?.[0]?.action?.[0]),
      },
      actions: actions?.map(a => resolveAction(a)),
      carousel: {
        heading: heading1,
        cards: cardCarousel
          ?.filter(card =>
            card.typeHandle === "action" ? card?.action?.[0] : true
          )
          .map(card => {
            if (card.action) {
              const action = resolveAction(card?.action?.[0]);
              return {
                heading: card.linkText || action.title,
                copy: action?.metaDescription,
                link: action.url,
                image: action?.metaImage,
                activistCodesExclusive: action.activistCodesExclusive,
              };
            }
            return {
              heading: card.linkText,
              copy: card.description,
              link: card.listLink?.url,
              image: resolveImage(card.image),
            };
          }),
      },
      social: {
        enabled: showSocialFeed,
        heading: heading3,
        embedCodes: socialFeed
          ?.filter(code => code.enabled)
          .map(code => {
            return code.code;
          }),
      },
      impact: {
        enabled: showImpactGrid,
        heading: heading2,
        copy: copy2 || null,
        impact: impactGrid
          ?.filter(card => card.enabled)
          .map(card => {
            return {
              ...card,
              image: resolveImage(card.image),
              logo: resolveImage(card.logo),
            };
          }),
      },
      relatedContent: {
        enabled: showImpactGrid,
        heading: heading4 || "Dig Into Related Issues",
        cards: relatedIssues
          ?.filter(card => card.enabled)
          .map(card => {
            switch (card.__typename) {
              case "Craft_relatedIssues_action_BlockType":
                // eslint-disable-next-line no-case-declarations
                const action = resolveAction(card?.action?.[0]);
                return {
                  image: action.metaImage,
                  program: action.site || "lakota",
                  url: `/action/${action.slug}`,
                  cta: action.linkText || "Take Action",
                  heading: action.heading,
                  label: "action",
                };
              case "entry":
                return card;
              default:
                return {
                  image: resolveImage(card.image),
                  program: card.organization,
                  url: card.linkLink,
                  cta: card.cta,
                  heading: card.heading,
                  label: card.contentType,
                };
            }
          }),
      },
      // other resolvers
      heading: heading0,
      metaImage: resolveImage(metaImage),
    };
  }
  return issue;
};

export default ResolveIssue;
