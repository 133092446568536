import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state";
import { DonationBanner, Icon } from "@atoms";

const DonationFeed = () => {
  const [{ contributions }] = useAppState();
  const [cList, setClist] = useState([]);
  useEffect(() => {
    contributions.contributions.then(c =>
      setClist(
        c
          .reverse()
          .filter(
            (cc, i) =>
              i < 10 ||
              Date.parse(cc.date) > new Date(new Date() - 5 * 60 * 1000)
          )
      )
    );
  }, [contributions]);
  return (
    <ul className="relative z-10 h-16 w-full overflow-hidden bg-black">
      {cList?.map((c, i) => (
        <DonationBanner
          {...c}
          delay={7 + 7 * i}
          style={{ zIndex: cList.length - i }}
        />
      ))}
      <li
        className={classNames(
          "text-uppercase font-serif absolute inset-0 z-10 flex items-center bg-black p-6 text-sm font-semibold text-white"
        )}
        style={{ zIndex: 0 }}
      >
        <Icon
          name="heart"
          className={classNames("mr-4 h-8 w-8 text-red", {})}
        />
        <span>Donate now to support Lakota People&apos;s Law Project!</span>
      </li>
    </ul>
  );
};

DonationFeed.defaultProps = {};

export default DonationFeed;
