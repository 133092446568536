import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";
import { m } from "framer-motion";
import { buildUrl, defaultParams } from "@utils/imageUtils";
import _brand from "@utils/brand";
import Text from "./Text";

const Image = ({
  image,
  ixParams,
  objectFit,
  fitHeight,
  caption,
  alt,
  backgroundColor,
  eager,
  resolution,
  round,
  fill,
  aspectRatio,
  widthAdjust: _widthAdjust,
  preserveAspectRatio,
  loadedState,
  draggable,
  className,
}) => {
  const { site } = _brand;
  const figure = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [inView, setInView] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loaded, setLoaded] = loadedState || useState(eager);
  const [widthAdjust, setWidthAdjust] = useState(_widthAdjust);
  const [sources, setSources] = useState([]);
  const [pr, setPr] = useState(resolution || 1);
  const { url, height, width, focalPoint, skipBuild, eagerUrl } = image || {};
  const [fpx, fpy] = focalPoint || [false, false];
  // calculate aspect ratio
  const ar = aspectRatio ? aspectRatio[0] / aspectRatio[1] : width / height;

  useEffect(() => {
    setPr(resolution || window.devicePixelRatio);
  }, []);

  const finalParams = { ...defaultParams(false, ar, pr), ...ixParams };

  if (fpx && fpy) {
    finalParams["fp-x"] = fpx;
    finalParams["fp-y"] = fpy;
    finalParams.fit = "crop";
    finalParams.crop = "focalpoint";
  }

  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    if (figure.current) {
      setWidthAdjust(
        (figure.current.clientWidth / windowSize).toFixed(1) || 0.1
      );
    }
  }, [figure.current, windowSize]);

  useEffect(() => {
    if (figure.current) {
      const arm = preserveAspectRatio
        ? 1 / ar
        : (figure.current.clientHeight / figure.current.clientWidth).toFixed(2);

      setSources(
        [360, 500, 800, 1360, 2000].map(size => ({
          url: !skipBuild
            ? buildUrl(url, {
                ...finalParams,
                w: parseInt(size * widthAdjust, 10) || size,
                h:
                  parseInt(
                    ar ? arm * size * widthAdjust : size * widthAdjust,
                    10
                  ) || size,
              })
            : url,
          size,
        }))
      );
    }
  }, [widthAdjust, url, figure.current]);

  // todo: uncomment to enable cloudsight or remove
  // useEffect(() => {
  //   if (process.env.GATSBY_CLOUDSIGHT) {
  //     const getCloudsightDetails = async imageUrl => {
  //       const { name } = await getAltTag(url, alt);
  //       setAiAlt(name);
  //     };
  //     getCloudsightDetails();
  //   }
  // }, []);

  // load in images after 3s
  useEffect(() => {
    if (site !== "lgca") {
      setTimeout(() => {
        setInView(true);
      }, 3000);
    }
  }, []);

  if (!image) return <div />;

  return (
    <figure
      ref={figure}
      className={classNames(
        className,
        "flex w-full flex-col overflow-hidden duration-200",
        {
          "absolute inset-0": fill,
          relative: !fill,
          "h-full": fitHeight,
          "w-full": !fitHeight,
          "rounded-full": round,
        }
      )}
    >
      <div
        className={classNames({ "absolute inset-0": fill })}
        style={{
          backgroundColor,
          paddingTop: 0,
          aspectRatio: fill ? null : ar,
        }}
      >
        <m.picture
          initial={eager ? null : "hidden"}
          variants={variants}
          className={classNames("h-full w-full duration-200")}
          animate={(loaded && !eager) || eager ? "visible" : "hidden"}
          viewport={{ once: true }}
          onViewportEnter={() => setInView(true)}
        >
          {/* load srcset */}
          {(inView || eager) && (
            <>
              {sources.map((s, i) => (
                <source
                  // eslint-disable-next-line react/no-array-index-key
                  key={s.url}
                  srcSet={s.url}
                  media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                />
              ))}
            </>
          )}
          {/* todo: fix alt tag fallback */}
          <img
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
            src={inView || eager ? eagerUrl : null}
            draggable={draggable}
            alt={alt || "image"}
            className={classNames(
              "absolute inset-0 h-full w-full transition duration-200 group-hover:scale-105",
              {
                "object-cover": objectFit === "cover",
                "object-contain": objectFit === "contain",
              }
            )}
          />
        </m.picture>
      </div>
      {caption && (
        <figcaption>
          <Text variant="caption" className="text-black">
            {caption}
          </Text>
        </figcaption>
      )}
    </figure>
  );
};

Image.defaultProps = {
  objectFit: "cover",
  eager: false,
  widthAdjust: 1,
  draggable: true,
};

export default Image;
