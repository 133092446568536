import React from "react";
import classNames from "classnames";
import { Text, Image, Icon, ButtonAlt } from "@atoms";
import { useAppState } from "@state";
import brand from "@utils/brand";

const SupporterModal = ({ image, bio, link, openState, params }) => {
  const [, dispatch] = useAppState();

  const { romero } = brand;

  return (
    <div className="z-10 mx-auto w-full max-w-xl overflow-y-auto">
      <div
        className={classNames(
          "relative flex flex-col items-center justify-center p-4 shadow-xl sm:p-6 md:p-10 lg:p-12",
          {
            "bg-white": !romero,
            "bg-cream": romero,
          }
        )}
      >
        <div className="absolute right-0 top-0 block p-4">
          <button
            type="button"
            className="text-red"
            onClick={() => dispatch({ type: "closeModal" })}
          >
            <Icon name="close" className="h-4 w-4" />
          </button>
        </div>
        <div className="relative flex h-24 w-24 items-center justify-center sm:h-48 sm:w-48">
          {image && (
            <Image image={image} {...(params || {})} placeholder={false} />
          )}
        </div>
        {bio && (
          <Text variant="body" className="mt-6">
            {bio}
          </Text>
        )}
        {link && (
          <div className="mt-6">
            <ButtonAlt to={link}>Learn More</ButtonAlt>
          </div>
        )}
      </div>
    </div>
  );
};

SupporterModal.defaultProps = {};

export default SupporterModal;
