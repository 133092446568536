import React from "react";

import { AppLink } from "@base";

import PaintStrokeIcon from "@svg/paintStroke.svg";
import PaintStrokeSmallIcon from "@svg/paintStrokeSmall.svg";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
}) => {
  let classes = `inline-flex justify-center items-center cursor-pointer items-center text-red relative z-0 uppercase font-bold group`;

  // eslint-disable-next-line react/no-unstable-nested-components
  const PaintStroke = ({ className: _classname }) => {
    if (size === "xxs" || size === "md") {
      return <PaintStrokeSmallIcon className={_classname} />;
    }
    return <PaintStrokeIcon className={_classname} />;
  };

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 leading-tighter text-xs";
      break;
    case "xxs":
      classes += " p-2 text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " p-4 text-xs";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    case "sm":
      classes += " py-4 px-6 text-xs";
      break;
    default:
      classes += " py-4 px-10 text-sm";
      break;
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={`${classes}`} onClick={onClick}>
        <PaintStroke className="ease absolute inset-0 h-full w-full fill-white transition duration-200 group-hover:opacity-50" />
        <div className="relative z-10 text-center">{children}</div>
      </LinkObj>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={`${classes}`} onClick={onClick}>
      <PaintStroke className="ease absolute inset-0 h-full w-full fill-white transition duration-200 group-hover:opacity-50" />
      <span className="relative z-10 block text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "white",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

export default Button;
