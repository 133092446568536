import React, { useEffect, useState } from "react";

import { AppLink } from "@base";
import { Icon } from "@atoms";
import { useAppState } from "@state";
import useTouchDevice from "@hooks/useTouchDevice";
import getContrast from "@utils/getContrast";
import classNames from "classnames";

const Bubble = ({ link, text, showArrow, isHomePage, isChapterEnd, color }) => {
  const isTouchDevice = useTouchDevice();
  const [{ ec }] = useAppState();
  const [hidden, setHidden] = useState(false);
  let textColor = "text-white";
  useEffect(() => {
    let timeout;
    if (!isHomePage && !isChapterEnd) {
      timeout = setTimeout(() => {
        setHidden(true);
      }, 7000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  if (
    !isHomePage &&
    ec?.colors?.accent &&
    getContrast(ec?.colors.accent, [255, 255, 255])
  ) {
    textColor = " text-black";
  }
  return (
    <AppLink
      to={link}
      className={classNames(
        "group absolute bottom-0 right-0 block -translate-y-1/2 transform pr-3 transition duration-200 md:translate-y-0",
        {
          "opacity-0 ": hidden,
        }
      )}
    >
      <div
        className={`font-sans animate-bubble flex-col ${
          color || "bg-accent"
        } float-right h-20 w-20 rounded-full px-3 py-6 sm:h-24 sm:w-24 ${textColor} font-extended flex -rotate-12 transform items-center justify-center text-center text-sm`}
        style={{ background: color }}
      >
        <span className="hover-pulse absolute inset-0 z-0 block rounded-full bg-accent" />
        {isTouchDevice && isHomePage && (
          <Icon
            name="swipe"
            fitHeight
            className={classNames("-mt-1 mb-1 h-10 w-10", {})}
          />
        )}
        <div className="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <span className="block flex w-full items-center justify-center text-center">
            {text}
          </span>
        </div>
        {showArrow && !isTouchDevice && isHomePage && (
          <Icon
            name="lgcaArrow"
            fitHeight
            className={classNames("-mb-1 h-6 w-6", {
              "-mb-2 mt-1 rotate-90 transform": isHomePage,
            })}
          />
        )}
      </div>
    </AppLink>
  );
};

Bubble.defaultProps = {
  isHomePage: false,
};

export default Bubble;
