import React from "react";

import classNames from "classnames";
// import { Wysiwyg } from "@base";

const InputField = ({
  type,
  name,
  formState,
  label,
  required,
  className,
  placeholder,
  prefix,
  min,
  max,
}) => {
  const [form, setForm] = formState;
  return (
    <div className={classNames(className, "relative flex flex-col")}>
      <label
        htmlFor={name}
        className="mb-1 flex items-center text-xxs font-bold uppercase text-black"
      >
        {label}
        {required && (
          <span className="ml-1 inline-flex text-base text-red">*</span>
        )}
      </label>
      <div className="relative">
        {prefix && (
          <span className="absolute bottom-0 left-0 top-0 flex items-center justify-center pl-2">
            {prefix}
          </span>
        )}
        {type !== "textarea" && (
          <input
            type={type}
            name={name}
            min={min}
            max={max || 1000000000}
            placeholder={placeholder}
            onChange={e => {
              setForm({ ...form, [name]: e.target.value });
            }}
            className={classNames(
              "w-full border border-gray bg-white p-3 focus:border-black",
              { "pl-5": prefix }
            )}
            required={required}
          />
        )}
        {type === "textarea" && (
          <textarea
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={e => {
              setForm({ ...form, [name]: e.target.value });
            }}
            className="h-36 w-full border border-gray bg-white p-3 focus:border-black"
            required={required}
          />
        )}
      </div>
    </div>
  );
};

InputField.defaultProps = {
  type: "text",
};

export default InputField;
