import React, { useMemo, useState, useEffect } from "react";
import useCraftPreview from "@hooks/useCraftPreview";
import { Meta } from "@atoms";
import { useAppState } from "@state";

export const HeadTemplate = React.memo(
  ({ data }) => {
    return (
      <>
        <link
          id="favicon"
          rel="icon"
          type="image/png"
          sizes="16x16 32x32"
          href="/images/icon.png"
        />
        <link
          id="at-preload-js"
          rel="preload"
          href={process.env.GATSBY_AT_JS}
          as="script"
          crossOrigin="anonymous"
        />
        <link
          id="at-preload-css"
          rel="preload"
          href={process.env.GATSBY_AT_CSS}
          as="style"
        />
        <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} />
        {/* allow social embeds */}
        <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script async src="https://platform.twitter.com/widgets.js" />
        <script async src="https://www.instagram.com/embed.js" />
        {data && <Meta {...data.meta} />}
      </>
    );
  },
  () => true
);

const QueryContainer = ({
  data: _data,
  pageContext,
  location,
  template: Template,
  dataResolver,
  queryName,
}) => {
  const [loaded, setLoaded] = useState(false);

  const [, dispatch] = useAppState();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const pageQuery = JSON.parse(process.env.GATSBY_QUERIES)[queryName];
  // get preview data if a preview
  const { data, isPreview } = useCraftPreview({
    data: _data?.craft,
    query: pageQuery,
    variables: { ...(pageContext || {}), uri: location.pathname.slice(1) },
  });
  // memoize based on whether or it is previewData (and only process data once!)
  const resolvedData = useMemo(
    () => (data ? dataResolver(data) : false),
    [isPreview, typeof data]
  );

  useEffect(() => {
    const { meta } = resolvedData;
    dispatch({
      type: "setMeta",
      meta: {
        url: meta?.url || null,
        title: meta?.metaTitle || meta?.title || null,
        description: meta?.metaDescription || null,
        twitterDescription:
          meta?.twitterDescription || meta?.metaDescription || null,
        twitterTitle:
          meta?.twitterTitle || meta?.metaTitle || meta?.title || null,
      },
    });
  }, [resolvedData, isPreview, typeof data]);

  // return the page component if there's data
  if (resolvedData) {
    return (
      <Template
        data={resolvedData}
        location={location}
        pageContext={pageContext}
      />
    );
  }
  // show preview loading when data is pending
  if (isPreview) {
    return (
      <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow items-center justify-center bg-black text-2xl text-white">
        <div className="font-bold">Preview Loading...</div>
      </div>
    );
  }
  if (isPreview && loaded) {
    return (
      <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow flex-col items-center justify-center gap-3 bg-red text-white">
        <div className="text-2xl font-bold">Error.</div>
        <div className="text-sm">Something went wrong. Try again later.</div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow flex-col items-center justify-center gap-3 bg-black text-white">
      <div className="aniamte-pulse text-2xl font-bold">Loading...</div>
    </div>
  );
};

export default QueryContainer;
