import React, { useState } from "react";
import { Container, Text, Icon, Button, ButtonAlt } from "@atoms";

const DonationList = ({ donations, heading }) => {
  const [visibleDonors, setVisibleDonors] = useState(4);
  return (
    <div className="bg-red py-12 md:py-24">
      <Container variant="xs">
        <div className="flex flex-col items-center space-y-6 text-center">
          <Text variant="h3" className="text-gold">
            {heading}
          </Text>
          {donations && (
            <div className="flex w-full flex-wrap">
              <ul className="mx-auto w-full max-w-lg space-y-[1px]">
                {donations.slice(0, visibleDonors)?.map((donor, i) => {
                  return (
                    <li className="inline-flex w-full">
                      <div className="bg-red-dark p-3 opacity-90">
                        <Icon name="donation" className="h-6 w-6 text-red" />
                      </div>
                      <div className="bg-red-dark flex w-full items-center justify-between p-3">
                        <Text variant="body" className="font-bold text-white">
                          {donor.name}
                        </Text>
                        <Text className="ml-auto text-sm text-white">{`$${donor.amount}`}</Text>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="w-full">
                {donations.length > visibleDonors && (
                  <ButtonAlt
                    className="mt-6 text-white"
                    color="white"
                    onClick={() => {
                      setVisibleDonors(visibleDonors + 4);
                    }}
                  >
                    View More
                  </ButtonAlt>
                )}
              </div>
            </div>
          )}
          {!donations.length && (
            <div className="flex items-center space-x-3 pb-6">
              <Text variant="body--xl-tight" className="text-white">
                Be the first to donate!
              </Text>
              <Button
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                size="sm"
                color="white"
              >
                Donate
              </Button>
            </div>
          )}
          <Button to="/create-fundraiser" color="white">
            Start Your Own Fundraiser
          </Button>
        </div>
      </Container>
    </div>
  );
};

DonationList.defaultProps = { heading: "Recent Donors" };

export default DonationList;
