import React, { useState, useEffect } from "react";

import classNames from "classnames";
// import { Wysiwyg } from "@base";
import DropDown from "./DropDown";

const InputField = ({
  name,
  formState,
  label,
  required,
  className,
  options,
  placeholder,
  initialValue,
  native,
}) => {
  const [form, setForm] = formState;
  const [selection, setSelection] = useState(initialValue);
  useEffect(() => {
    setForm({ ...form, [name]: selection });
  }, [selection]);
  return (
    <div className={classNames(className, "relative flex flex-col")}>
      <label
        htmlFor={name}
        className="text-sblack mb-1 flex items-center text-xxs font-bold uppercase"
      >
        {label}
        {required && (
          <span className="ml-1 inline-flex text-base text-red">*</span>
        )}
      </label>
      {!native && (
        <DropDown
          options={options}
          model={[selection, setSelection]}
          placeholder={placeholder}
        />
      )}
      {native && (
        <select
          className="border border-gray p-2"
          onChange={e => setSelection(e.target.value)}
        >
          <option disabled selected>
            {placeholder || "Select One"}
          </option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

InputField.defaultProps = {
  type: "text",
};

export default InputField;
