/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react";
import { m } from "framer-motion";
import { Image, Text, Icon } from "@atoms";
import _brand from "@utils/brand";
import classNames from "classnames";

const CardArticle = ({ icon, heading, copy, image, colors, color }) => {
  const [hoverOpen, setHoverOpen] = useState(false);
  const [clickOpen, setClickOpen] = useState(false);
  const hasCopy = copy && copy.length > 1;
  const { lgca, lakota, romero, brand } = _brand;

  const copyRef = useRef();

  return (
    <div
      className={classNames(
        "group relative flex flex-col overflow-hidden shadow-lg",
        {
          "bg-brown": lakota,
          "bg-red": lgca,
          "h-full bg-blue": romero,
        }
      )}
      onMouseEnter={() => {
        setHoverOpen(true);
      }}
      onMouseLeave={() => {
        setHoverOpen(false);
      }}
    >
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 z-0">
          <div className="absolute bottom-0 right-0 left-0 z-10 h-25 bg-gradient-to-b from-transparent to-black" />
          <div className="absolute inset-0 z-10 bg-black opacity-0 transition duration-200 group-hover:opacity-75" />
          <div className="absolute inset-0 z-0">
            <Image image={image} fill />
          </div>
        </div>
        <div
          className={
            (classNames("ease absolute inset-0 z-10 transition duration-700"),
            {
              "bg-gradient-to-b from-transparent to-black opacity-30": !romero,
              "": romero,
            })
          }
        />
      </div>
      <div className="z-10 mt-auto h-full p-3 lg:p-6">
        {/* open/close icon for mobile */}
        {hasCopy && !romero && (
          <div className="absolute top-0 right-0 flex h-12 w-12 items-center justify-center">
            <Icon
              name="close"
              className={`ease absolute h-4 w-4 cursor-pointer text-white transition duration-200
              ${
                clickOpen && !hoverOpen
                  ? "opacity-50 hover:opacity-100"
                  : "opacity-0"
              } `}
              onClick={() => {
                setClickOpen(false);
              }}
            />
            <Icon
              name="info"
              className={`ease absolute h-4 w-4 cursor-pointer text-white transition duration-200
              ${
                !clickOpen && !hoverOpen
                  ? "opacity-50 hover:opacity-100"
                  : "opacity-0"
              } `}
              onClick={() => {
                setClickOpen(true);
              }}
            />
          </div>
        )}
        {/* the actual content */}
        <div className="pointer-events-none relative z-10 pt-48">
          <m.div
            animate={() =>
              hoverOpen || clickOpen
                ? { y: 0 }
                : { y: copyRef?.current?.clientHeight || 0 }
            }
            className={classNames(` ${hasCopy ? "pb-3 lg:pb-6" : ""}`, {
              "pr-12": lakota,
            })}
          >
            <Text
              variant={{ lgca: "h6", lakota: "h4", romero: "h3" }[brand]}
              className={classNames("text-white", {
                "font-bold": !romero,
                "font-medium": romero,
              })}
            >
              {heading}
            </Text>
          </m.div>
          {hasCopy && (
            <m.div
              ref={copyRef}
              animate={() =>
                hoverOpen || clickOpen
                  ? { y: 0 }
                  : { y: copyRef?.current?.clientHeight || 0 }
              }
              className="-mb-3 transform pb-3 lg:-mt-3 lg:-mb-6 lg:pb-6"
            >
              <Text
                className={classNames("text-white", {
                  "leading-tight": !romero,
                  "text-sm font-medium leading-normal md:text-lg": romero,
                  "font-serif": lakota,
                  "font-sans font-bold": lgca,
                })}
              >
                {copy}
              </Text>
            </m.div>
          )}
        </div>
      </div>
    </div>
  );
};

CardArticle.defaultProps = {};

export default CardArticle;
