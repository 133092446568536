import React from "react";

import { AppLink } from "@base";
import { Icon, Image, Text } from "@atoms";
import brand from "@utils/brand";
import classNames from "classnames";

const WritingTease = d => {
  const { lgca, romero, site } = brand;
  const { image, url, title } = d;
  const variants = {
    lgca: "lg",
    romero: "h5",
    lakota: "body--large-tight",
  };
  return (
    <AppLink
      to={url}
      className={classNames("group flex w-full text-left", {
        "items-center": lgca,
      })}
    >
      {!image && !lgca && (
        <div
          className={classNames("flex-shrink-0", {
            "mr-4 text-red": !romero,
            "mr-8 text-blue": romero,
          })}
        >
          <Icon name="document" className="h-8 w-8" />
        </div>
      )}
      {!image && lgca && (
        <div className="mr-4 flex-shrink-0 text-red">
          <div
            className={classNames(
              "absolute relative inset-0 flex w-full items-center justify-center"
            )}
          >
            <Icon
              name="lgcaSunOutline"
              className="relative z-10 h-16 w-16 text-white"
            />
            <div className="absolute inset-0 z-0 flex h-full w-full transform items-center justify-center text-yellow">
              <svg
                className="h-16 w-16 fill-current"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50" />
              </svg>
            </div>
          </div>
        </div>
      )}
      {image && (
        <div className="mr-4 flex-shrink-0 text-red">
          <Image image={image} />
        </div>
      )}
      <Text
        variant={variants[site]}
        className={classNames({ "font-extended font-bold": !romero })}
      >
        {title}
      </Text>
    </AppLink>
  );
};

WritingTease.defaultProps = {};

export default WritingTease;
