import React from "react";

import { Image, Square, Text, Icon, Button, ButtonAlt } from "@atoms";
import _brand from "@utils/brand";
import classNames from "classnames";

const Card = ({ tag, icon, heading, copy, image, link, text, small }) => {
  const { romero, lgca, brand, lakota } = _brand;
  return (
    <Square
      className={classNames(
        "ease text-white shadow-lg transition duration-700 group-hover:shadow-xl",
        {
          "bg-black": !romero,
          "rounded-xl bg-blue": romero,
        }
      )}
    >
      <div className="absolute inset-0 z-0">
        <div
          className={classNames("absolute inset-0 z-0", {
            "overflow-hidden rounded-xl": romero,
          })}
        >
          <Image
            image={image}
            ixParams={
              {
                lakota: { gam: 25, shad: 25, sat: 10, exp: -2, con: 25 },
                romero: { gam: 10, shad: 10, sat: 5, exp: -2, con: 5 },
              }[brand]
            }
            fill
          />
        </div>
        <div
          className={classNames(
            "ease absolute inset-0 z-10 transition duration-700",
            {
              "bg-gradient-to-b from-transparent to-black opacity-60 group-hover:opacity-100":
                !romero,
              "rounded-xl bg-blue opacity-0 group-hover:opacity-75": romero,
            }
          )}
        />
      </div>
      <div
        className={classNames("relative z-10 flex h-full flex-col", {
          "p-3 lg:p-4": !romero,
          "p-4 lg:p-6": romero,
        })}
      >
        {icon && <Icon name={icon} className="h-12 w-12" />}
        <div className="mt-auto">
          <div className="-mx-1 flex items-end justify-between">
            <div>
              <Text
                variant={
                  {
                    lgca: "body--large-tight",
                    lakota: "h4",
                    romero: "h3",
                  }[brand]
                }
                className={classNames({
                  "font-bold": !romero,
                })}
              >
                {heading}
              </Text>
              {copy && (
                <Text
                  className={classNames("leading-tight", {
                    "mt-2": !romero,
                    "font-sans block font-medium opacity-0 transition-all duration-200 group-hover:my-2 group-hover:opacity-100":
                      romero,
                    "text-sm": small,
                    "text-sm md:text-lg": !small,
                  })}
                >
                  {copy}
                </Text>
              )}
            </div>
            {!romero && (
              <div className={classNames("ml-auto px-1", {})}>
                <Button rounded={lgca} size="xxs">
                  <Icon
                    name={lakota ? "arrow" : "lgcaArrow"}
                    className="-m-1 h-4 w-4 transform transition duration-500 ease-in-out group-hover:scale-110"
                  />
                </Button>
              </div>
            )}
          </div>
          {romero && link && (
            <ButtonAlt to={link} light color="white" className="mt-2">
              {text}
            </ButtonAlt>
          )}
        </div>
      </div>
      <div />
    </Square>
  );
};

Card.defaultProps = {};

export default Card;
