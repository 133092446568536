import React from "react";

import classNames from "classnames";
import { Text, CbWrapper } from "@atoms";
import brand from "@utils/brand";

const CbSubheading = ({ subheading, indent, type, inverse }) => {
  const { lakota, lgca, romero } = brand;
  return (
    <CbWrapper type={type} indent={indent}>
      <Text
        variant={lgca ? "h5" : "h4"}
        inverse={inverse}
        className={classNames({
          "text-red": lakota,
          "text-blue": (lgca || romero) && !inverse,
        })}
      >
        {subheading}
      </Text>
    </CbWrapper>
  );
};

CbSubheading.defaultProps = {};

export default CbSubheading;
