import React from "react";

import classNames from "classnames";
// import PaintStroke from "@svg/paintStroke.svg";
import brand from "@utils/brand";

const Divider = () => {
  const { romero, lgca, lakota } = brand;
  return (
    <div
      className={classNames("border-b-2", {
        "border-red-light my-6 opacity-50": lakota,
        "my-4 border-gold opacity-50": romero,
        "my-6 border-blue": lgca,
      })}
    >
      {/* TODO Make this swatch */}
      {/* <PaintStroke className="h-1 w-full" /> */}
    </div>
  );
};

Divider.defaultProps = {};

export default Divider;
