import React from "react";

import { Text, Image } from "@atoms";
import dayjs from "dayjs";
import brand from "@utils/brand";
import classNames from "classnames";

const CardExcerpt = ({
  date,
  copy,
  heading,
  subheading,
  typeHandle,
  logo,
  color,
  colors,
}) => {
  const { lakota, lgca } = brand;
  return (
    <div
      className={classNames(
        "relative flex h-full flex-col p-4 shadow-xl lg:p-6",
        {
          "bg-white": lakota,
          [colors.lgca[color].copy]: lgca,
          [colors.lgca[color].background]: lgca,
        }
      )}
    >
      {lakota && (
        <div className="relative mb-2 h-16">
          <Image
            image={logo}
            ixParams={{ fit: "max" }}
            objectFit="contain"
            fitHeight
          />
        </div>
      )}
      {lakota && (
        <div className="border-red-light mt-2 mb-3 border-t pt-2 text-center text-sm text-gray">
          Posted: {dayjs(date).format("MMMM DD, YYYY")}
        </div>
      )}
      <div className={classNames("", { [colors.lgca[color].heading]: lgca })}>
        <Text quotes variant="h5" className="font-bold">
          {heading}
        </Text>
      </div>
      {lgca && (
        <div className="font-extended my-4 text-xs uppercase tracking-0.5">
          Posted: {dayjs(date).format("MMMM DD, YYYY")}
        </div>
      )}
      {lakota && (
        <div>
          <Text variant="body--large-tight" className="font-bold text-red">
            {subheading}
          </Text>
        </div>
      )}
      <div>
        <Text
          variant="body"
          className={classNames("rich-text", { "font-bold": lgca })}
        >
          {copy}
        </Text>
      </div>
      {lgca && (
        <div className="mt-2 ml-auto w-36">
          <Image
            image={logo}
            ixParams={{ fit: "max" }}
            objectFit="contain"
            fitHeight
          />{" "}
        </div>
      )}
    </div>
  );
};

CardExcerpt.defaultProps = {};

export default CardExcerpt;
