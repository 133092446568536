import React from "react";

import classNames from "classnames";
import { AppLink } from "@base";
import Arrow from "@svg/arrow.svg";
import brand from "@utils/brand";

const BigLink = ({ children, to, className: _className, key, onClick }) => {
  const { lakota, romero } = brand;
  return (
    <AppLink
      key={key}
      to={to}
      className={`group flex ${_className}`}
      onClick={onClick}
    >
      {lakota && (
        <div className="romeroicon mr-2 h-5 w-5 flex-shrink-0 transition duration-200 group-hover:text-red">
          <Arrow />
        </div>
      )}
      <div
        className={classNames(
          "font-extended font-bold transition duration-200",
          {
            "underline-hover": !romero,
            "hover:text-gold": romero,
          }
        )}
      >
        {children}
      </div>
    </AppLink>
  );
};

BigLink.defaultProps = {};

export default BigLink;
