import React, { useEffect, useState } from "react";

import classNames from "classnames";
import brand from "@utils/brand";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";

const CardExpanded = ({
  image,
  heading,
  fName,
  lName,
  goal,
  link,
  centered,
  slug,
}) => {
  const [{ contributions }] = useAppState();

  // const capitalize = s => {
  //   if (typeof s !== "string") return "";
  //   return s.charAt(0).toUpperCase() + s.slice(1);
  // };
  const [total, setTotal] = useState(0);

  // todo: optimize this
  useEffect(() => {
    if (contributions?.contributions) {
      contributions.contributions.then(res => {
        const donations = res
          .filter(c => c.fundraiser && c.fundraiser.slug === slug)
          .map(c => parseInt(c.amount, 10));
        setTotal(donations.reduce((a, b) => a + b, 0));
      });
    }
  }, [contributions]);

  const { romero } = brand;
  return (
    <AppLink
      to={link}
      className="group flex w-full flex-grow flex-col justify-start bg-white shadow-lg transition duration-200 hover:shadow-xl"
    >
      <div className="w-full flex-shrink-0 overflow-hidden">
        <div
          className="relative -mx-px transform transition duration-200 group-hover:scale-105"
          style={{ paddingBottom: `${(5 / 8) * 100}%` }}
        >
          <div className="absolute inset-0">
            <Image image={image} fill />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "flex h-full w-full flex-grow flex-col items-center p-3 text-center lg:p-4",
          {
            // "justify-center items-center text-center": centered,
            // "justify-start items-start text-left": !centered,
          }
        )}
      >
        <Text
          variant="h6"
          className={classNames("line-clamp-3", {
            "font-bold text-red": !romero,
            "text-blue": romero,
          })}
        >
          {heading}
        </Text>
        <div className="leading-tighter font-sans mt-2 text-xs font-bold uppercase">
          {`Created by: ${fName} ${lName}`}
        </div>

        <div className="mt-auto w-full pt-3">
          <div className="mb-1 flex w-full justify-between text-xs">
            <span>{`$${total.toLocaleString()} Raised`}</span>
            <span>{`$${goal.toLocaleString()} Goal`}</span>
          </div>
          <div className="border-1 relative mb-2 h-4 w-full overflow-hidden rounded-full border-gray bg-gray-300">
            <div
              className="absolute left-0 top-0 h-4 w-1/2 bg-red transition transition-all duration-700"
              style={{
                width: `${((total / goal) * 100).toFixed(2)}%`,
              }}
            />
          </div>
          {link?.link && (
            <div className="mt-auto flex items-center justify-center py-3">
              <ButtonAlt
                size="sm"
                noIcon={!romero}
                color={romero ? "gold" : "red"}
              >
                Support
              </ButtonAlt>
            </div>
          )}
        </div>
      </div>
    </AppLink>
  );
};

CardExpanded.defaultProps = {
  centered: false,
};

export default CardExpanded;
