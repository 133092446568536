import React from "react";

import classNames from "classnames";
import { Button, Icon } from "@atoms";
import useLakotaAcFooterData from "@staticQueries/lakotaAc/FooterQuery";
import useLgcaFooterData from "@staticQueries/lgca/FooterQuery";
import useLakotaFooterData from "@staticQueries/lakota/FooterQuery";
import useLgcaAcFooterData from "@staticQueries/lgcaAc/FooterQuery";
import useRomeroFooterData from "@staticQueries/romero/FooterQuery";
import _brand from "@utils/brand";

const SocialLinks = ({ large, color, className: _className }) => {
  const { brand, site } = _brand;
  const social = {
    lakota: useLakotaFooterData().social,
    lgca: useLgcaFooterData().social,
    lakotaAc: useLakotaAcFooterData().social,
    lakotaGc: useLakotaAcFooterData().social,
    lgcaAc: useLgcaAcFooterData().social,
    romero: useRomeroFooterData().social,
  };

  return (
    <div className="-mx-2 flex">
      {social[site].map(s => {
        return (
          <div key={s.url} className="px-2">
            <Button
              to={s.url}
              rounded
              color={
                color || { lgca: "blue", romero: "gold", lakot: "red" }[brand]
              }
              size="xxs"
            >
              <Icon
                name={s.platform}
                className={classNames(
                  _className,
                  { "h-6 w-6": large },
                  { "h-3 w-3": !large }
                )}
                fitHeight
              />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

SocialLinks.defaultProps = {
  large: false,
};

export default SocialLinks;
