import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";
import _brand from "@utils/brand";
import PaintStroke from "@svg/paintStrokeSmall.svg";

const Carousel = ({
  children,
  indicators,
  maxVisible,
  className: _className,
}) => {
  const { romero, lakota, lgca, brand } = _brand;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const screens = {
    xxs: { max: "375px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1400px", // larger than 15" macbook pro
    xxl: "2000px",
  };

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={classNames("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && (
          <div className="translate-y-50 absolute top-0 bottom-0 z-10 hidden transform items-center justify-center md:-left-4 md:flex lg:-left-12">
            <button
              className={classNames(
                "group flex items-center justify-center p-2 text-white transition duration-200",
                {
                  "opacity-0": currentSlide <= 0,
                  "bg-red": lgca,
                  "bg-gold": romero,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              {lakota && (
                <PaintStroke
                  className={classNames(
                    "ease group-hover:fill-red-dark absolute inset-0 h-full w-full fill-red transition duration-200"
                  )}
                />
              )}
              <Icon
                name={
                  {
                    lakota: "arrow",
                    lgca: "chevron",
                    romero: "chevron",
                  }[brand]
                }
                className={classNames(
                  "relative right-0 transition duration-200 group-hover:-translate-x-1.5",
                  {
                    "arrow h-4 w-4 rotate-180": lakota,
                    "chevron h-5 w-5 rotate-90": lgca || romero,
                  }
                )}
              />
            </button>
          </div>
        )}
        <m.div
          animate={carouselControls}
          className="flex"
          transition={{ duration: 0.5 }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
          dragDirectionLock
        >
          {slides.map((slide, i) => (
            <div
              key={slide.uid || `slide-${i}`}
              className={classNames("relative duration-200", {
                "opacity-0":
                  i < currentSlide || i + 1 > currentSlide + visibleSlides,
              })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </m.div>
        {/* next button */}
        {slideCount > 1 && (
          <div className="absolute top-0 bottom-0 z-10 hidden items-center justify-center md:-right-4 md:flex lg:-right-12">
            <button
              className={classNames(
                "group flex items-center justify-center p-2 text-white transition duration-200",
                {
                  "opacity-0": currentSlide >= slideCount - visibleSlides,
                  "bg-red": lgca,
                  "bg-gold": romero,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              {lakota && (
                <PaintStroke
                  className={classNames(
                    "ease group-hover:fill-red-dark absolute inset-0 h-full w-full fill-red transition duration-200"
                  )}
                />
              )}
              <Icon
                name={
                  {
                    lakota: "arrow",
                    lgca: "chevron",
                    romero: "chevron",
                  }[brand]
                }
                className={classNames(
                  "relative right-0 transition duration-200 group-hover:translate-x-1.5",
                  {
                    "h-4 w-4": lakota,
                    "h-5 w-5 -rotate-90": lgca || romero,
                  }
                )}
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {slideCount > 1 && slideCount > visibleSlides && (
        <ul
          className={classNames(
            "mt-2 flex flex-wrap items-center justify-center",
            {
              "md:hidden": !indicators,
            }
          )}
        >
          {slides.map((slide, i) => (
            <li key={slide.uid}>
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={classNames(
                  "m-1.5 block h-3 w-3 rounded-full transition duration-200",
                  {
                    "opacity-50": currentSlide !== i,
                    "bg-red": lakota || lgca,
                    "bg-gold": romero,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
  indicators: true,
};

export default Carousel;
