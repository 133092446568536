import React from "react";
import { m } from "framer-motion";
import { Text, Image, AnimateIn, Icon } from "@atoms";
import brand from "@utils/brand";
import classNames from "classnames";

const CardQuote = ({ quote, citation, image, colors, color }) => {
  const { lgca, lakota } = brand;
  return (
    <div
      className={classNames("relative shadow-lg", {
        "bg-white": lakota,
        "p-6 text-center": lgca,
        [colors.lgca[color].background]: lgca,
        [colors.lgca[color].copy]: lgca,
      })}
    >
      <div className="relative p-3 lg:p-6">
        {lgca && (
          <div
            className={classNames("pointer-events-none absolute inset-0 z-0", [
              colors.lgca[color].heading,
            ])}
          >
            <svg className="block h-full w-full stroke-current">
              <m.rect
                className={classNames("transition duration-1000")}
                width="100%"
                height="100%"
                strokeWidth="4"
                fill="none"
                initial={{ strokeDashoffset: "0%" }}
                whileInView={{ strokeDashoffset: "400%" }}
                strokeDasharray="400%"
              />
            </svg>
          </div>
        )}
        <AnimateIn>
          {image && (
            <div className="mx-auto mb-3 h-32 w-32 overflow-hidden rounded-full">
              <Image
                image={image}
                ixParams={{ fit: "facearea", facepad: 10 }}
                aspectRatio={[1, 1]}
              />
            </div>
          )}
          {lgca && (
            <m.div initial={{ y: "100%" }} whileInView={{ y: 0 }}>
              <Icon
                name="lgcaQuote"
                className={classNames("h-6 w-6", colors.lgca[color].heading)}
              />
            </m.div>
          )}
          {quote && (
            <Text
              quotes={lakota}
              variant={lakota ? "h4" : "h6"}
              className={classNames("mb-3", { "font-serif font-bold": lakota })}
            >
              {quote}
            </Text>
          )}
          {citation && (
            <m.div initial={{ y: "-100%" }} whileInView={{ y: 0 }}>
              <Text
                variant={lakota ? "body--large-tight" : "citation"}
                className={classNames("citation mt-4 text-sm", {
                  [colors.lgca[color].heading]: lgca,
                })}
              >
                {citation}
              </Text>
            </m.div>
          )}
        </AnimateIn>
      </div>
    </div>
  );
};

CardQuote.defaultProps = {};

export default CardQuote;
